import {getKey} from './get-key.js'

const dateInputStyle = {
  fontSize: '18px',
  padding: '5px',
  border: '1px solid rgb(222,222,222)',
  borderRadius: '4px'
}

const dateToYYYYMMDD = (date) => {
  if (date == null) {
    return ''
  } else {
    return date.toISOString().split('T')[0]
  }
}

const datePlus9ToYYYYMMDD = (value, days = 9) => {
  //push back cancel date 9 days
  if (value == '') {
    return ''
  } else {
    let plus_9 = new Date(value)
    plus_9.setDate(plus_9.getDate()+days)
    return dateToYYYYMMDD(plus_9)
  }
}

const processOnChangeBulkStartShipDate = (myState, updateState, event) => {
  let {value} = event.currentTarget
  let {cads, startShipDate, bulkCancelDate, cancelDate, isMultiplePage} = myState

  if (value != '') {
    bulkCancelDate = datePlus9ToYYYYMMDD(value)
    cads.forEach((cad) => {
      let key = getKey(cad)
      startShipDate[key] = value
      cancelDate[key] = bulkCancelDate
    })
  }

  updateState({bulkStartShipDate: value, startShipDate, bulkCancelDate, cancelDate, pendingChanges: true})
}

const processOnChangeStartShipDate = (myState, updateState, cadId, event) => {
  let {value} = event.currentTarget
  let {startShipDate, cancelDate} = myState

  startShipDate[cadId] = value

  if (value != '') {
    cancelDate[cadId] = datePlus9ToYYYYMMDD(value)
  }

  updateState({startShipDate, cancelDate, pendingChanges: true})
}

const processOnChangeBulkCancelDate = (myState, updateState, event) => {
  let {value} = event.currentTarget
  let {cads, cancelDate} = myState

  if (value != '') {
    cads.forEach((cad) => {
      let key = getKey(cad)
      cancelDate[key] = value
    })
  }

  updateState({bulkCancelDate: value, cancelDate, pendingChanges: true})
}

const processOnChangeCancelDate = (myState, updateState, cadId, event) => {
  //cancel should be after start ship
  let {value} = event.currentTarget
  let {cancelDate} = myState
  cancelDate[cadId] = value
  updateState({cancelDate, pendingChanges: true})
}

export {
  dateInputStyle,
  processOnChangeBulkStartShipDate,
  processOnChangeStartShipDate,
  processOnChangeBulkCancelDate,
  processOnChangeCancelDate,
  datePlus9ToYYYYMMDD
}
