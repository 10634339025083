import React, {Component} from 'react'
import {Accordion, Icon} from 'semantic-ui-react'
import {sumShipped, sumShippedDollar, sumSalesOrders, sumSalesOrdersDollar, sumHold, sumHoldDollar, sumWorksheet, sumWorksheetDollar} from './sum.js'
import {shippedTotalWithSalesHistoryAccount, shippedDollarTotalWithSalesHistoryAccount} from './cad-totals/shipped-total-with-sales-history-account.js'
import {getSalesOrdersFilteredByStyleNumber} from './sales-orders/get'
import {userIdShowAllOrders} from './get-rep-options'

const withOrder = (cadReducer) => {
  if (['Hold', 'PO', 'Shipped', 'Worksheet'].includes(cadReducer.cadStatus)) {
    return ' on '+cadReducer.cadStatus
  } else if (cadReducer.cadStatus == 'Not Purchased') {
    return ' w/ NO ORDERS'
  } else {
    return ''
  }
}

class CadTotalsAccordion extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {activeIndex: -1}
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const {activeIndex} = this.state
    const {cadsToSearch, salesHistoryAccount, isCustomer, cadReducer, user} = this.props

    const content = (
      cadsToSearch == null
      ?
      null
      :
      <React.Fragment>
        <div className="ui label" id="number-of-styles-ui-label">
          # Styles
          <div className="detail">
            {cadsToSearch.length}
          </div>
        </div>&nbsp;&nbsp;
        <div className="ui label">
          On Hand {withOrder(cadReducer)}
          <div className="detail">
            {
              cadsToSearch.reduce((a, b) => a + ((b.on_hand > 0 ? b.on_hand : 0) || 0), 0).totalFormat()
            }
          </div>
        </div>&nbsp;&nbsp;
        <div className="ui label">
          ATS {withOrder(cadReducer)}
          <div className="detail">
            {
              cadsToSearch.reduce((a, b) => a + ((b.available > 0 ? b.available : 0) || 0), 0).totalFormat()
            }
          </div>
        </div>&nbsp;&nbsp;
        <div className="ui label" id="future-ats-ui-label">
          Future ATS {withOrder(cadReducer)}
          <div className="detail">
            {
              cadsToSearch.reduce((a, b) => a + ((b.future_ats > 0 ? b.future_ats : 0) || 0), 0).totalFormat()
            }
          </div>
        </div>&nbsp;&nbsp;
        <div className="ui label">
          Future Stock {withOrder(cadReducer)}
          <div className="detail">
            {
              cadsToSearch.reduce((a, b) => a + ((b.future_stock > 0 ? b.future_stock : 0) || 0), 0).totalFormat()
            }
          </div>
        </div>&nbsp;&nbsp;
        <div className="ui label">
          Oversold
          <div className="detail">
            {
              cadsToSearch.reduce((a, b) => a + ((b.future_ats > 0 ? 0 : b.future_ats) || 0), 0).totalFormat()
            }
          </div>
        </div>&nbsp;&nbsp;
        <div className="ui label">
          Pending PI (Future Stock)
          <div className="detail">
            {
              cadsToSearch.reduce((a, b) => a + ((b.pending_pi ? b.future_stock : 0) || 0), 0).totalFormat()
            }
          </div>
        </div>&nbsp;&nbsp;
        {
          this.props.cadReducer.cadStatus == 'FACTORY-RTS' ?
          <div className="ui label">
            FACTORY-RTS
            <div className="detail">
              {
                cadsToSearch.reduce((a, b) => a + ((b.rts_fob_qty > 0 ? b.rts_fob_qty : 0) || 0), 0).totalFormat()
              }
            </div>
          </div>
          :
          null
        }

        {
          this.props.cadReducer.cadStatus == 'Shipped'
          ?
          salesHistoryAccount == ''
          ?
          <div className="ui label">
            Shipped QTY
            <div className="detail">
              {
                window.salesAccountDataFilteredByStyleNumber == null ?
                null
                :
                cadsToSearch.reduce((a, b) => a + sumShipped(window.salesAccountDataFilteredByStyleNumber[b.style_number.flatUpcase()], this.props.cadReducer.defaultAccount) , 0).totalFormat()
              }
            </div>
          </div>
          :
          shippedTotalWithSalesHistoryAccount(cadsToSearch, salesHistoryAccount)
          :
          null
        }

        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'Shipped' ? <span>&nbsp;&nbsp;</span> : null}

        {
          this.props.cadReducer.cadStatus == 'Shipped' && !isCustomer && !userIdShowAllOrders.includes(user.id)
          ?
          salesHistoryAccount == ''
          ?
          <div className="ui label">
            Shipped $
            <div className="detail">
              {
                window.salesAccountDataFilteredByStyleNumber == null ?
                null
                :
                Math.round(cadsToSearch.reduce((a, b) => a + sumShippedDollar(window.salesAccountDataFilteredByStyleNumber[b.style_number.flatUpcase()], this.props.cadReducer.defaultAccount) , 0)).totalFormat()
              }
            </div>
          </div>
          :
          shippedDollarTotalWithSalesHistoryAccount(cadsToSearch, salesHistoryAccount)
          :
          null
        }

        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'PO' ? <span>&nbsp;&nbsp;</span> : null}

        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'PO' ?
          <div className="ui label">
            PO QTY
            <div className="detail">
              {
                window.salesOrderDataFilteredByStyleNumber == null ?
                null
                :
                cadsToSearch.reduce((a, b) => a + sumSalesOrders(getSalesOrdersFilteredByStyleNumber(b.style_number, b.id), this.props.cadReducer.defaultAccount) , 0).totalFormat()
              }
            </div>
          </div>
          : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'PO' && !isCustomer ? <span>&nbsp;&nbsp;</span> : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'PO' && !isCustomer && !userIdShowAllOrders.includes(user.id) ?
          <div className="ui label">
            PO $
            <div className="detail">
              {
                window.salesOrderDataFilteredByStyleNumber == null ?
                null
                :
                Math.round(cadsToSearch.reduce((a, b) => a + sumSalesOrdersDollar(getSalesOrdersFilteredByStyleNumber(b.style_number, b.id), this.props.cadReducer.defaultAccount) , 0)).totalFormat()
              }
            </div>
          </div>
          : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'Hold' ? <span>&nbsp;&nbsp;</span> : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'Hold' ?
          <div className="ui label">
            Hold QTY
            <div className="detail">
              {
                window.salesOrderDataFilteredByStyleNumber == null ?
                null
                :
                cadsToSearch.reduce((a, b) => a + sumHold(getSalesOrdersFilteredByStyleNumber(b.style_number, b.id), this.props.cadReducer.defaultAccount) , 0).totalFormat()
              }
            </div>
          </div>
          : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'Hold' && !isCustomer ? <span>&nbsp;&nbsp;</span> : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'Hold' && !isCustomer && !userIdShowAllOrders.includes(user.id) ?
          <div className="ui label">
            Hold $
            <div className="detail">
              {
                window.salesOrderDataFilteredByStyleNumber == null ?
                null
                :
                Math.round(cadsToSearch.reduce((a, b) => a + sumHoldDollar(getSalesOrdersFilteredByStyleNumber(b.style_number, b.id), this.props.cadReducer.defaultAccount) , 0)).totalFormat()
              }
            </div>
          </div>
          : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'Worksheet' ? <span>&nbsp;&nbsp;</span> : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'Worksheet' ?
          <div className="ui label">
            Worksheet QTY
            <div className="detail">
              {
                window.salesOrderDataFilteredByStyleNumber == null ?
                null
                :
                cadsToSearch.reduce((a, b) => a + sumWorksheet(getSalesOrdersFilteredByStyleNumber(b.style_number, b.id), this.props.cadReducer.defaultAccount) , 0).totalFormat()
              }
            </div>
          </div>
          : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'Worksheet' && !isCustomer ? <span>&nbsp;&nbsp;</span> : null}
        {salesHistoryAccount == '' && this.props.cadReducer.cadStatus == 'Worksheet' && !isCustomer && !userIdShowAllOrders.includes(user.id) ?
          <div className="ui label">
            Worksheet $
            <div className="detail">
              {
                window.salesOrderDataFilteredByStyleNumber == null ?
                null
                :
                Math.round(cadsToSearch.reduce((a, b) => a + sumWorksheetDollar(getSalesOrdersFilteredByStyleNumber(b.style_number, b.id), this.props.cadReducer.defaultAccount) , 0)).totalFormat()
              }
            </div>
          </div>
          : null
        }
      </React.Fragment>
    )

    return (
      <Accordion style={{marginBottom: '14px'}}>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
          style={{display: 'inline-block'}}
        >
          <Icon name='dropdown' />
          Totals
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <div style={{fontSize: '10px', lineHeight: '13px', marginBottom: '33px'}}>
            <p style={{paddingLeft: '15px'}}>
              {content}
            </p>
          </div>
        </Accordion.Content>
      </Accordion>
    )
  }
}

export default CadTotalsAccordion
